<template>
	<div class="nav">
		<img src="../../assets/image/pc/pcHeader/logo.png" alt="" />
	</div>
</template>

<script>
export default {
	data() {
		return {}
	},
	created() {},
	methods: {}
}
</script>

<style lang="scss" scoped>
.nav {
	background: #fff;
	position: sticky;
	top: 0;
	display: flex;
	padding: 10px 20px;
	justify-content: space-between;
	z-index: 999;
	border-bottom: solid 1px #e8e8e8;
	img {
		width: 91px;
		height: 21px;
	}
}
</style>
