<template>
	<div @touchstart="() => {}">
		<Nav />
		<router-view />
		<MobileFooter />
	</div>
</template>

<script>
import Nav from '@/components/mobile/nav.vue'
import MobileFooter from '@/components/mobile/mobileFooter.vue'
export default {
	components: {
		Nav,
		MobileFooter,
	},
	data() {
		return {}
	},
}
</script>

<style lang="scss" scoped></style>
