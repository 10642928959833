import { render, staticRenderFns } from "./mobileIndex.vue?vue&type=template&id=3d246d16&scoped=true&"
import script from "./mobileIndex.vue?vue&type=script&lang=js&"
export * from "./mobileIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d246d16",
  null
  
)

export default component.exports